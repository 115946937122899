import React from "react";
import{Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";



ChartJS.register
(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
)

ChartJS.defaults.backgroundColor = '#f5f5f5';
ChartJS.defaults.borderColor ="#f5f5f5";
ChartJS.defaults.color = "#f5f5f5";

ChartJS.defaults.font.size = 14;
ChartJS.defaults.font.family = "bio-sans, sans-serif";



export const options = 
{
    responsive: true,
    plugins: 
    {
        legend: 
        {
            position: "top" as const,
           
        },
        title:
        {
            display: true,
            text: "Calories",
        }
       }
    }


    const labels = ['M', 'T', 'W', 'Th', 'F', 'S', 'S'];



    export const data =
    {
        labels,
        datasets: [
            {label: 'Calories',
            data: labels.map(() => faker.datatype.number({min: 10, max: 2000})),
            backgroundColor: "rgb(166,167,203)",}
        ]
    }

export function Linegraphcal()
{
    return <Line width={500} height={-1300} options={{maintainAspectRatio: true, layout: {padding: {left: 7, right: 7}}}} data={data} />;
}



export default Linegraphcal;




