import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import ThemeProvider from "react-bootstrap/ThemeProvider";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBicycle,
  faBirthdayCake,
  faHeartPulse,
  faHomeUser,
  faMale,
  faUser,
  faUserCircle,
  faWater,
  faWeight,
  faFemale,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

function Profile() {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <Container fluid="xxs">
        <Navbar id="container-nav" expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              <Link to="/profile" aria-current="page">
                <FontAwesomeIcon icon={faHomeUser} className="user-icon" />
              </Link>
            </Navbar.Brand>
            <Navbar.Brand>
              <Link to="/userone" aria-current="page" id="name">
                DROP Cycling &trade;
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              className="navbar-toggler"
              id="border"
            >
              <FontAwesomeIcon icon={faBars} id="toggle" />
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              ></Nav>
              <Form className="d-flex" id="search">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="outline-none" id="search-btn">
                  Search
                </Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="user">
          <Row className="g-4">
            <Col sm={{ span: 10, offset: 1 }}>
              <p className="userprofile-pagination">
                <FontAwesomeIcon icon={faUserCircle} />
              </p>
            </Col>
          </Row>

          <Card className="card-design-profile">
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="sub-topic">User Name</p>
              </Col>
            </Row>
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="sub-topic-small">Joined September 2020</p>
              </Col>
            </Row>
          </Card>
          <Card className="card-design-profile-two">
            <Row>
              <Col sm={{ span: 12 }}>
                <p className="profile-header">Profile</p>

                <p className="basic-text">
                  <FontAwesomeIcon icon={faUser} id="icon-space" />
                  UserName | John Doe
                </p>
                <p className="basic-text">
                  <FontAwesomeIcon icon={faBirthdayCake} id="icon-space" />
                  Birthday | October 31, 1965
                </p>
                <p className="basic-text">
                  {" "}
                  <FontAwesomeIcon icon={faMale} id="icon-space" />
                  <FontAwesomeIcon icon={faFemale} id="icon-space" />
                  Height | 5' 10"
                </p>
                <p className="basic-text-divide">
                  {" "}
                  <FontAwesomeIcon icon={faWeight} id="icon-space" /> Weight |
                  210lbs
                </p>
                <p className="achievement">Recent Achievements</p>
                <p className="basic-text">
                  <FontAwesomeIcon icon={faBicycle} id="icon-space" />
                  Distance | 165 miles in a week
                </p>
                <p className="basic-text">
                  <FontAwesomeIcon icon={faWater} size={50} id="icon-space" />
                  Hydration | 32oz of water in a day
                </p>
                <p className="basic-text-divide">
                  <FontAwesomeIcon icon={faHeartPulse} id="icon-space" />
                  BPM | 169bpm is your top hear rate
                </p>
                <p className="legal">Legal</p>
                <p className="basic-text">
                  <FontAwesomeIcon icon={faFileAlt} id="icon-space" />
                  Terms of Use
                </p>
                <p className="basic-text">
                  <FontAwesomeIcon icon={faFileAlt} id="icon-space" />
                  Privacy Policy
                </p>
              </Col>
            </Row>
          </Card>
          <footer>
            <Row>
              <Col sm={{ span: 6, offset: 3 }}>
                <p className="copy">Copyright &copy; 2023</p>
              </Col>
            </Row>
          </footer>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default Profile;
