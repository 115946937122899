import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import ThemeProvider from "react-bootstrap/ThemeProvider";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Vertbar from "../charts/vertbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCalendarCheck,
  faHomeUser,
  faMailBulk,
  faShop,
  faStopwatch20,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FaBicycle, FaHeartbeat, FaFire, FaWater } from "react-icons/fa";
import { Link } from "react-router-dom";

//import Linegraph from "../charts/dist/linegraph";

function UserOne() {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <Container fluid="xxs">
        <Navbar id="container-nav" expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              <Link to="/profile" aria-current="page">
                <FontAwesomeIcon icon={faHomeUser} className="user-icon" />
              </Link>
            </Navbar.Brand>
            <Navbar.Brand>
              <Link to="/userone" aria-current="page" id="name">
                DROP Cycling &trade;
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              className="navbar-toggler"
              id="border"
            >
              <FontAwesomeIcon icon={faBars} id="toggle" />
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              ></Nav>
              <Form className="d-flex" id="search">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="outline-none" id="search-btn">
                  Search
                </Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="user" id="section-one">
          <Row>
            <Col sm={{ span: 10, offset: 1 }}>
              <p className="hello">Hello, User!</p>
              <p className="hello-two">
                This is your stats for the week ending in 09-02-2022.
              </p>
            </Col>
          </Row>
          <Row>
            <Card className="card-design-top-chart">
              <Row className="g-4">
                <Col sm={{ span: 12 }}>
                  <p className="summary-data">Summary</p>
                </Col>
              </Row>

              <Row className="g-4">
                <Col sm={{ span: 12 }}>
                  <div className="charts">
                    <Vertbar />
                  </div>
                </Col>
              </Row>
            </Card>
          </Row>
          <Card className="card-design-top">
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="stats">
                  <FaBicycle size={20} /> 4.6 mi average distance
                </p>
                <p className="stats">
                  <FaFire size={20} /> 1112 average calories
                </p>
                <p className="stats">
                  <FaHeartbeat size={20} /> 129 average bpm
                </p>
              </Col>
            </Row>
          </Card>

          <Row xs={1} md={3} className="g-4" id="cards">
            <Col sm={{ span: 4, offset: 1 }}>
              <Link to="/distance" aria-current="page" className="nav-link">
                <Card className="card-design">
                  <div className="card-nav">
                    <FaBicycle size={50} />
                  </div>

                  <Card.Body>
                    <Card.Text className="card-text">Distance</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col sm={{ span: 4, offset: 1 }}>
              <Link to="/bpm" aria-current="page" className="nav-link">
                <Card className="card-design">
                  <div className="card-nav">
                    <FaHeartbeat size={50} />
                  </div>
                  <Card.Body>
                    <Card.Text className="card-text">BPM</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>

          <Row xs={1} md={3} className="g-4" id="cards-two">
            <Col sm={{ span: 4, offset: 1 }} style={{ marginBottom: "0px" }}>
              <Link to="/calories" aria-current="page" className="nav-link">
                <Card className="card-design">
                  <div className="card-nav">
                    <FaFire size={50} />
                  </div>
                  <Card.Body>
                    <Card.Text className="card-text">Calories</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col sm={{ span: 4, offset: 1 }} style={{ marginBottom: "0px" }}>
              <Link to="/hydration" aria-current="page" className="nav-link">
                <Card className="card-design">
                  <div className="card-nav">
                    <FaWater size={50} />
                  </div>
                  <Card.Body>
                    <Card.Text className="card-text">Hydration</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>
        </div>

        <Navbar fixed="bottom" expand="lg" id="container-nav-btm">
          <Container>
            <Navbar.Brand href="/userone">
              <Nav.Link href="#section-one">
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  className="user-icon-btm"
                />
              </Nav.Link>
            </Navbar.Brand>
            <Nav.Item>
              <Link to="/crew" aria-current="page">
                <FontAwesomeIcon icon={faUsers} id="temp-place-two" />
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/time" aria-current="page">
                <FontAwesomeIcon icon={faStopwatch20} id="temp-place-three" />
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/message" aria-current="page">
                <FontAwesomeIcon icon={faMailBulk} id="temp-place-four" />
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/shop" aria-current="page">
                <FontAwesomeIcon icon={faShop} id="temp-place-five" />
              </Link>
            </Nav.Item>
          </Container>
          <Row>
            <footer>
              <Col sm={{ span: 6, offset: 3 }}>
                <p className="copyNav">Copyright &copy; 2023</p>
              </Col>
            </footer>
          </Row>
        </Navbar>
      </Container>
    </ThemeProvider>
  );
}

export default UserOne;
