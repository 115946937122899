import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import ThemeProvider from "react-bootstrap/ThemeProvider";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Linegraph from "../charts/linegraphhydration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faFaceSmile,
  faHomeUser,
  faWater,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

function Hydration() {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <Container fluid="xxs">
        <Navbar id="container-nav" expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              <Link to="/profile" aria-current="page">
                <FontAwesomeIcon icon={faHomeUser} className="user-icon" />
              </Link>
            </Navbar.Brand>
            <Navbar.Brand>
              <Link to="/userone" aria-current="page" id="name">
                DROP Cycling &trade;
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              className="navbar-toggler"
              id="border"
            >
              <FontAwesomeIcon icon={faBars} id="toggle" />
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              ></Nav>
              <Form className="d-flex" id="search">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="outline-none" id="search-btn">
                  Search
                </Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="user">
          <Row>
            <Col sm={{ span: 10, offset: 1 }}>
              <p className="hydrate-pagination">
                <FontAwesomeIcon icon={faWater} />
              </p>
              <p className="goodjob-text-hydration">
                What an impressive week! You are reaching your goals without
                hesitation.
                <FontAwesomeIcon
                  icon={faFaceSmile}
                  className="face-smile-hydration"
                />
              </p>
            </Col>
          </Row>

          <Card className="card-date-hydrate">
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="date-hydration">
                  September 18 &#8211; September 24
                </p>
              </Col>
            </Row>
          </Card>

          <Card className="card-chart-hydrate">
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="topic-data-hydration">Water</p>
              </Col>
            </Row>
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <div className="chart-four">
                  <Linegraph />
                </div>
              </Col>
            </Row>
          </Card>

          <Card className="card-date-hydrate">
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="date-two-hydration">
                  September 25 &#8211; October 1
                </p>
              </Col>
            </Row>
          </Card>

          <Card className="card-chart-hydrate">
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="topic-data-hydration">Water</p>
              </Col>
            </Row>
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <div className="chart-four">
                  <Linegraph />
                </div>
              </Col>
            </Row>
          </Card>

          <Card className="card-date-hydrate">
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="date-three-hydration">
                  {" "}
                  October 2 &#8211; October 8
                </p>
              </Col>
            </Row>
          </Card>

          <Card className="card-chart-hydrate-three">
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="topic-data-hydration">Water</p>
              </Col>
            </Row>
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <div className="chart-four">
                  <Linegraph />
                </div>
              </Col>
            </Row>
          </Card>
          <footer>
            <Row>
              <Col sm={{ span: 6, offset: 3 }}>
                <p className="copy">Copyright &copy; 2023</p>
              </Col>
            </Row>
          </footer>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default Hydration;
