import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  
  
} from "chart.js";


import {Bar} from "react-chartjs-2";

import {faker} from '@faker-js/faker'




ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  
);

ChartJS.defaults.backgroundColor = "#f5f5f5";
ChartJS.defaults.borderColor = "#f5f5f5";
ChartJS.defaults.color = "#f5f5f5";

ChartJS.defaults.font.size =8.65;
ChartJS.defaults.font.family = "bio-sans , sans-serif";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      offset: true,

    },
    title: {
      display: true,
      text: "Statistics",
    },
  },
};










const labels = ["M", "T", "W", "Th", "F","S", "S"];

export const data = {
  labels: labels,
  datasets: [
    {
      label: "Distance",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgb(166,167,203)",
  
     
    },
    {
      label: "Calories",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1010})),
      backgroundColor: "rgb(109,207,246)",
      
    },
    {
      label: "BPM",
      data: labels.map(() => faker.datatype.number({min:10, max: 200})),
      backgroundColor: "rgb(249, 173,129)",
      
    }
  ],
};
export function Vertbar() {
return <Bar width={500} height={-1300} options={{maintainAspectRatio: true, layout: {padding: {left: 17, right: 17}} }} data={data} />;
} 




export default Vertbar;